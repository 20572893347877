import { ColumnType } from "Utils/tableColumns/functions"
import { Table } from "components/Table"
import React from "react"
import { useTrans } from "translations"

type Props = {
	data: { [k: string]: any }[]
	columns: ColumnType[]
}

export const DetailsTable: React.FC<Props> = ({ data, columns }) => {
	const { t } = useTrans()

	return (
		<div className={`col-span-${columns.length} bg-hoverColor pt-4 pb-8 pl-4 sm:pl-12`}>
			<h6 className="px-2 text-start text-sm font-semibold text-black">
				{t("statisticsLabels:breakdownOnWasteTypes")}
			</h6>
			<Table
				columns={columns}
				data={data}
				className="w-[calc(60%-50px)]"
				headerClassName="bg-hoverColor z-0"
				rightAlign
			/>
		</div>
	)
}

export default DetailsTable
