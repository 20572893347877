import { DropdownArrowIcon } from "Icons/DropdownArrow"
import { FC } from "react"
import { Link } from "react-router-dom"
import { Card, ICardComponentProps } from "./card"

export const LinksCard: FC<{ links: ILinkEntryProps[] } & ICardComponentProps> = ({
	title,
	links,
}) => {
	return (
		<Card title={title} halfCard>
			<div className="flex-col gap-4">
				{links.map((link, index) => (
					<LinkEntry key={`linkCard-entry-${index}`} {...link} />
				))}
			</div>
		</Card>
	)
}

export interface ILinkEntryProps {
	heading: string
	description?: string
	linkTo: string
}

const LinkEntry: FC<ILinkEntryProps> = ({ heading, linkTo, description }) => {
	return (
		<Link to={linkTo}>
			<div className="flex items-center sm:gap-6 bg-grey2 px-6 py-4">
				<div className="flex flex-col sm:flex-row w-full sm:gap-6 sm:items-center">
					<p className="xl:whitespace-nowrap font-bold">{heading}</p>
					<p className="flex-1 text-sm text-grey6">{description}</p>
				</div>
				<DropdownArrowIcon className="h-4 sm:h-2.5 w-4 sm:w-2.5 -rotate-90" />
			</div>
		</Link>
	)
}
