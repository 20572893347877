import LoadingTable from "components/LoadingTable"
import { Page } from "components/Page"
import { Card } from "components/pageCards/card"
import {
	createTimeFilters,
	FilterTimeFrameValue,
	getTenantCategoryOptions,
} from "components/pageCards/filterSort/filterCreators"
import {
	TimeFilterCustomDate,
	getCustomTimeFrame,
	getTimeFrame,
} from "components/pageCards/filterSort/filterTimeFrame"
import moment from "moment-timezone"
import { useCallback, useMemo, useState } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { useDataWareHouse } from "Utils/api/datawarehouse/request"
import { calcAverages, getTenantDetailsTableData } from "./functions"
import DetailsTable from "./components/DetailsTable"
import {
	expandedTenantDetailsColumns,
	tenantDetailsColumns,
} from "Utils/tableColumns/tenantDetailsColumns"
import { Table } from "components/Table"
import { EmptyView } from "components/EmptyView/EmptyView"
import { ActiveOptions } from "components/pageCards/filterSort/FilterSortContext"
import { FilterSort, FilterSortMenuType } from "components/pageCards/filterSort/types"
import { OPTION_ALL, hasNonAllOptionActive } from "components/pageCards/filterSort/constants"
import { useConfigService } from "pages/configuration/useConfigService"
import { MediaQuery, useMediaQuery } from "Utils/useMediaQuery"
import { AveragesListMobile } from "./components/AvarageListMobile"
import { timezoneStore } from "api/hooks/useConfig"

const defaultTimeframe = FilterTimeFrameValue.LAST_30_DAYS

export const TenantDetails = () => {
	const { currentTerminal } = useTerminalsState()
	const { t } = useTrans()
	const { wasteTypeConfig } = useConfigService()
	const matches = useMediaQuery(MediaQuery.ExtraSmall)

	const breadcrumbs = useMemo(
		() => [{ url: "/tenant/dashboard", name: t("sidebarLabels:tenantFollowUp") }],
		[t]
	)
	const { timezone } = timezoneStore()

	const [{ timeframe: [selectedTimeframe] = [], tenantCategory }, setActiveOptions] = useState<
		ActiveOptions<"timeframe" | "tenantCategory">
	>({} as any)

	const { startTimeISO, endTimeISO } = useMemo(() => {
		if (selectedTimeframe?.option === "interval.custom") {
			return getCustomTimeFrame(selectedTimeframe.value as TimeFilterCustomDate)
		} else {
			return getTimeFrame((selectedTimeframe?.value as FilterTimeFrameValue) ?? defaultTimeframe)
		}
	}, [selectedTimeframe])

	// The start of the comparison period (an equal length period ending when the current period starts)
	const firstPeriodStartTimeISO = useMemo(() => {
		return moment(startTimeISO)
			.subtract(moment(endTimeISO).diff(moment(startTimeISO), "days"), "days")
			.tz(timezone, true)
			.toISOString()
	}, [startTimeISO, endTimeISO, timezone])

	const { wasteTypes: sanityData, tenantCategories } = useCommonEntitiesStore()

	const { data: firstPeriod, isLoading: isLoadingFirstPeriod } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: {
			startTimeISO: firstPeriodStartTimeISO,
			endTimeISO: startTimeISO,
			commercialCustomerCategory: hasNonAllOptionActive(tenantCategory)
				? tenantCategory[0].value
				: undefined,
		},
	})

	const { data: currentPeriod, isLoading: isLoadingCurrentPeriod } = useDataWareHouse({
		endpoint: "terminal/customers",
		pathParams: { id: currentTerminal.id },
		filters: {
			startTimeISO,
			endTimeISO,
			commercialCustomerCategory: hasNonAllOptionActive(tenantCategory)
				? tenantCategory[0].value
				: undefined,
		},
	})

	const { data: dwhData, isLoading: isLoadingAggregate } = useDataWareHouse({
		endpoint: "terminal",
		terminalId: currentTerminal.id,
		filters: getTimeFrame(FilterTimeFrameValue.LAST_30_DAYS),
	})

	const commercialRealEstate = dwhData?.terminal

	let averages = useMemo(
		() =>
			firstPeriod && currentPeriod && commercialRealEstate
				? calcAverages(firstPeriod, currentPeriod, commercialRealEstate)
				: {},
		[firstPeriod, currentPeriod, commercialRealEstate]
	)

	const tenantDetailsTableData = useMemo(
		() =>
			firstPeriod && currentPeriod && sanityData
				? getTenantDetailsTableData({ firstPeriod, currentPeriod, sanityData, wasteTypeConfig })
				: [],
		[firstPeriod, currentPeriod, sanityData, wasteTypeConfig]
	)

	const isLoading = isLoadingFirstPeriod || isLoadingCurrentPeriod || isLoadingAggregate

	const filters: FilterSort[] = [
		createTimeFilters(
			{ id: "timeframe", defaultValue: defaultTimeframe, menuType: FilterSortMenuType.TimeFrame },
			[
				FilterTimeFrameValue.LAST_30_DAYS,
				FilterTimeFrameValue.LAST_6_FULL_MONTHS,
				FilterTimeFrameValue.LAST_FULL_YEAR,
			]
		),
		{
			options: getTenantCategoryOptions(tenantCategories),
			title: "statisticsLabels:tenantCategory",
			id: "tenantCategory",
			type: "filter",
			menuType: FilterSortMenuType.Select,
			defaultValue: OPTION_ALL,
		},
	]

	const getExpandedRowView = useCallback(
		(values: any) => <DetailsTable columns={expandedTenantDetailsColumns} data={values.details} />,
		[]
	)

	const averagesColumns = useMemo(
		() => tenantDetailsColumns.map((el, i) => ({ ...el, label: i === 0 ? "" : el.label })),
		[]
	)

	const averagesData = useMemo(
		() => [{ ...averages, name: t("statisticsLabels:average") }],
		[averages, t]
	)

	return (
		<Page title="drawerLabels:tenantDetails" fullHeight breadcrumbsPaths={breadcrumbs}>
			<Card
				filters={filters}
				onFilterOptionChange={setActiveOptions}
				overflow="auto"
				hasXContentPadding={false}
				hasYContentPadding={false}
			>
				{!tenantDetailsTableData.length ? (
					<EmptyView type="noData" />
				) : (
					<div className="my-6 mx-4 sm:mx-8 h-full sm:min-w-[900px] flex-col">
						<h6 className="mb-4 font-dmSans text-large font-medium text-black">
							{t("statisticsLabels:overview")}
						</h6>
						{isLoading ? (
							<div className="mb-14">
								<LoadingTable rows={2} />
							</div>
						) : matches ? (
							<AveragesListMobile
								rows={averagesColumns}
								data={averagesData}
								ignoreRowNames={["name"]}
							/>
						) : (
							<Table
								columns={averagesColumns}
								data={averagesData}
								sortable={false}
								headerClassName="justify-items-start pr-4"
								rowClassName="bg-selectedColor bg-selectedColor pl-6"
								rightAlign
							/>
						)}

						<h6 className="mb-1 font-dmSans text-large font-medium text-black mt-10">
							{t("statisticsLabels:details")}
						</h6>

						{isLoading ? (
							<LoadingTable rows={20} />
						) : (
							<Table
								columns={tenantDetailsColumns}
								data={tenantDetailsTableData}
								getExpandedRowView={getExpandedRowView}
								isCollapsible
								headerClassName="justify-items-start pl-6"
								rowClassName="pl-6"
								rightAlign
								isSmallScreen={matches}
							/>
						)}
					</div>
				)}
			</Card>
		</Page>
	)
}

export default TenantDetails
