import { useGlobalAlert } from "States/globalAlert"
import { trpc } from "Utils/trpc"

type Options = {
	onSuccessFunc?: () => void
}

export const useEmailJob = (options?: Options) => {
	const { setGlobalAlert } = useGlobalAlert()

	const { mutateAsync: addEmailJob, isLoading: isAddingEmailJob } =
		trpc.config.createEmailJob.useMutation({
			onSuccess: () => {
				options?.onSuccessFunc?.()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:recipientSaved",
				})
			},
			onError: error => {
				console.log(error)
				setGlobalAlert({
					type: "error",
					message: error.message,
				})
			},
		})

	const { mutateAsync: updateEmailJob, isLoading: isUpdatingEmailJob } =
		trpc.config.updateEmailJob.useMutation({
			onSuccess: () => {
				options?.onSuccessFunc?.()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:changesSaved",
				})
			},
			onError: error => {
				setGlobalAlert({
					type: "error",
					message: error.message,
				})
			},
		})

	const { mutateAsync: deleteEmailJob, isLoading: isDeletingEmailJob } =
		trpc.config.deleteEmailJob.useMutation({
			onSuccess: () => {
				options?.onSuccessFunc?.()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:changesSaved",
				})
			},
			onError: error => {
				setGlobalAlert({
					type: "error",
					message: error.message,
				})
			},
		})

	return {
		addEmailJob,
		isAddingEmailJob,
		updateEmailJob,
		isUpdatingEmailJob,
		deleteEmailJob,
		isDeletingEmailJob,
	}
}
