import { useTerminalsState } from "States/Terminals"
import { trpc } from "Utils/trpc"
import { Button } from "components/button"
import { StyledSelect } from "components/StyledSelect"
import { Toggle } from "components/Toggle"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { useTrans } from "translations"
import { LoadingIndicator } from "Icons/loadingIndicator"

type Props = {
	hasUnsavedChanges: boolean
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

const currencies = [
	{ label: "GBP", value: "gbp" },
	{ label: "NOK", value: "nok" },
]

export const Payments: FC<Props> = ({ setHasUnsavedChanges }) => {
	const { currentTerminal } = useTerminalsState()
	const { t } = useTrans()
	const [selectedCurrency, setSelectedCurrency] = useState<string>("")
	const [price, setPrice] = useState<string>("")
	const {
		data: pricing,
		isLoading: isLoadingPricing,
		refetch: refetchPricing,
	} = trpc.payments.getPricing.useQuery({
		terminalId: currentTerminal?.id,
	})

	useEffect(() => {
		if (pricing) {
			setSelectedCurrency(pricing.currency)
			setPrice(`${pricing.unit_amount / 100}`)
		}
	}, [pricing])

	useEffect(() => {
		if (pricing) {
			setHasUnsavedChanges(
				pricing.currency !== selectedCurrency || `${pricing.unit_amount / 100}` !== price
			)
		}
	}, [pricing, selectedCurrency, price, setHasUnsavedChanges])

	const {
		data: terminalConfig,
		refetch: refetchTerminalConfig,
		isLoading: isLoadingConfig,
	} = trpc.config.getTerminalConfig.useQuery({
		terminalId: currentTerminal?.id,
	})

	const { mutate: mutatePricing, isLoading: isSavingPricing } =
		trpc.payments.setPricing.useMutation({
			onSettled: () => refetchPricing(),
		})
	const { mutate: mutateTerminalConfig, isLoading: isUpdatingTerminalConfig } =
		trpc.config.updateTerminalConfig.useMutation({
			onSettled: () => refetchTerminalConfig(),
		})

	const isLoading = isLoadingPricing || isLoadingConfig || isUpdatingTerminalConfig

	const onSave = useCallback(async () => {
		mutatePricing({
			terminalId: currentTerminal?.id,
			price_cents: Number(price) * 100,
			currency: selectedCurrency,
		})
	}, [currentTerminal?.id, price, selectedCurrency, mutatePricing])

	const togglePayments = (enableTenantPayments: boolean) => {
		mutateTerminalConfig({
			terminalId: currentTerminal?.id,
			enableTenantPayments,
		})
	}

	const content = useMemo(() => {
		if (!terminalConfig?.enableTenantPayments) {
			return null
		}

		return (
			<div>
				<div className="mt-4">
					<h2 className="text-lg font-semibold">{t("configLabels:pricing")}</h2>
					<div className="flex items-center mt-2">
						<input
							type="text"
							value={price}
							onChange={e => setPrice(e.target.value)}
							className="rounded-none h-10 w-64 mr-2 box-border border border-black p-3 font-dmSans text-sm placeholder:text-grey6 disabled:border-grey3 disabled:text-grey6 focus:outline focus:outline-2 focus:outline-offset-[-2px]"
						/>
						<StyledSelect
							name={"configLabels:currency"}
							options={currencies}
							value={currencies.find(c => c.value === selectedCurrency)}
							onChange={v => setSelectedCurrency(v.value)}
						/>
					</div>
					<div className="mt-3">
						<Button
							label={t("actions:save")}
							onClick={onSave}
							disabled={isLoading}
							loading={isSavingPricing}
						/>
					</div>
				</div>
			</div>
		)
	}, [isLoading, price, terminalConfig, t, onSave, selectedCurrency, isSavingPricing])

	return (
		<>
			<div className="mt-4">
				{!terminalConfig ? (
					<div className="flex justify-center items-center w-[200px] min-h-[100px]">
						<LoadingIndicator />
					</div>
				) : (
					<Toggle
						label={t("configLabels:paymentsEnabled")}
						checked={terminalConfig?.enableTenantPayments ?? false}
						onChange={checked => togglePayments(checked)}
					/>
				)}

				{content}
			</div>
		</>
	)
}
