import { Button } from "components/button"
import Meatballs from "Icons/Meatballs"
import { useCallback, useMemo, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import { useTrans } from "translations"
import useClickoutside from "Utils/useClickoutside"

type TableActionsProps = {
	row: any
	onEdit: (row: any) => void
	onDelete: (id: string) => void
	disabled?: boolean
	className?: string
}

export const TableActions: React.FC<TableActionsProps> = ({
	row,
	onEdit,
	onDelete,
	disabled,
	className,
}) => {
	const { t } = useTrans()
	const [showButtons, setShowButtons] = useState<boolean>(false)
	const containerWrapper = useRef(null)

	useClickoutside(containerWrapper, () => setShowButtons(false))

	const onMenuButtonClick = useCallback(
		(e: any) => {
			e.preventDefault()
			e.stopPropagation()

			setShowButtons(!showButtons)
		},
		[setShowButtons, showButtons]
	)

	const editButtonClick = useCallback(
		(e: any) => {
			e.preventDefault()
			e.stopPropagation()

			onEdit(row)

			setShowButtons(false)
		},
		[onEdit, row, setShowButtons]
	)

	const deleteButtonClick = useCallback(
		(e: any) => {
			e.preventDefault()
			e.stopPropagation()

			onDelete(row.id)
			setShowButtons(false)
		},
		[onDelete, row, setShowButtons]
	)

	const buttons = useMemo(
		() => [
			{
				title: t("actions:edit"),
				onClick: editButtonClick,
			},
			{
				title: t("actions:delete"),
				onClick: deleteButtonClick,
				style: "text-carrotRed",
			},
		],
		[t, editButtonClick, deleteButtonClick]
	)

	return (
		<div className={twMerge("relative w-8 h-8 mr-4", className)} ref={containerWrapper}>
			<div
				onClick={onMenuButtonClick}
				className={twMerge(
					"w-8 h-8 p-1.5 rotate-90 pointer hover:bg-grey2 rounded-md flex justify-center cursor-pointer",
					showButtons && "bg-grey3"
				)}
			>
				<Meatballs />
			</div>
			{showButtons && (
				<div className="absolute mt-1 right-0 z-20 border-1 border-grey2 shadow">
					{buttons.map(({ onClick, title, style }, index) => (
						<Button
							label={title}
							key={title}
							className={twMerge(
								"w-40 h-12 bg-white border text-sm pl-4 flex items-center hover:bg-grey1",
								index < buttons.length - 1 && "border-b-0",
								style
							)}
							onClick={onClick}
							disabled={disabled}
							clipped={false}
						/>
					))}
				</div>
			)}
		</div>
	)
}
