import classNames from "classnames"
import { Button } from "components/button"
import { RemoveButton } from "components/RemoveButton"
import { TableActions } from "components/TableWithSelect/components/TableActions"
import Skeleton from "react-loading-skeleton"

type EntryType = { id: string }

type EntryRenderProps = {
	label: string
	labelBottom?: string
}

type ListFormProps<T extends EntryType> = {
	title: string
	buttonTitle: string
	data: T[]
	onAdd: () => void
	onEdit?: (entry: T) => void
	onDelete: (id: string) => void
	entryRender: (entry: T) => EntryRenderProps
	className?: string
	disabled?: boolean
	loadingData?: boolean
	emptyMessage?: string
	addButtonDisabled?: boolean
}

export function ListForm<T extends EntryType>({
	title,
	buttonTitle,
	data = [],
	onAdd,
	onEdit,
	onDelete,
	entryRender,
	className,
	disabled,
	loadingData,
	emptyMessage,
	addButtonDisabled,
}: ListFormProps<T>) {
	return (
		<div className={classNames("md:border divide-y text-sm", className)}>
			<div className="md:px-6 px-3 py-4 flex justify-between items-center">
				<h3 className="font-dmSans font-medium">{title}</h3>
				<Button
					label={buttonTitle}
					color="secondary"
					onClick={onAdd}
					disabled={disabled || addButtonDisabled}
					className="md:min-w-[200px]"
				/>
			</div>
			<Entries
				data={data}
				onEdit={onEdit}
				onDelete={onDelete}
				entryRender={entryRender}
				disabled={disabled}
				loadingData={loadingData}
				emptyMessage={emptyMessage}
			/>
		</div>
	)
}

type EntriesProps<T extends EntryType> = {
	data: T[]
	onEdit?: (entry: T) => void
	onDelete: (id: string) => void
	entryRender: (entry: T) => EntryRenderProps
	disabled?: boolean
	loadingData?: boolean
	emptyMessage?: string
}

const Entries = <T extends EntryType>({
	data,
	onEdit,
	onDelete,
	entryRender,
	disabled,
	loadingData,
	emptyMessage,
}: EntriesProps<T>) => {
	if (loadingData) {
		return <Skeleton count={3} height={48} />
	}

	if (data.length === 0) {
		return <div className="p-6 text-center bg-grey1">{emptyMessage}</div>
	}

	return data.map(entry => (
		<Entry
			key={entry.id}
			entry={entry}
			{...entryRender(entry)}
			onEdit={onEdit}
			onDelete={onDelete}
			disabled={disabled}
		/>
	))
}

type EntryProps<T extends EntryType> = EntryRenderProps & {
	entry: T
	onEdit?: (entry: T) => void
	onDelete: (id: string) => void
	disabled?: boolean
}

const Entry = <T extends EntryType>({
	entry,
	label,
	labelBottom,
	onEdit,
	onDelete,
	disabled,
}: EntryProps<T>) => (
	<div className="md:px-6 px-3 py-4 flex justify-between items-center">
		<div>
			<div className={classNames(labelBottom && "font-medium")}>{label}</div>
			{labelBottom && <div>{labelBottom}</div>}
		</div>
		{onEdit ? (
			<TableActions
				className="mr-0"
				onEdit={() => onEdit(entry)}
				onDelete={() => onDelete(entry.id)}
				row={entry}
				disabled={disabled}
			/>
		) : (
			<RemoveButton onClick={() => onDelete(entry.id)} type="button" disabled={disabled} />
		)}
	</div>
)
