import classNames from "classnames"
import { Button } from "components/button/index"
import React, { ComponentProps, FC, MutableRefObject, useMemo } from "react"
import { Link } from "react-router-dom"
import { useTrans } from "translations"
import { FilterSorts } from "./filterSort/FilterSorts"
import { FilterSort } from "./filterSort/types"
import { ActiveOptions, FilterSortProvider } from "./filterSort/FilterSortContext"
import { ExportButton } from "components/ExportButton"

export enum CardComponentVariant {
	DRAWER = "Drawer",
	STANDARD = "Standard",
}

export enum CardComponentFilterLocation {
	LEFT = "left",
	RIGHT = "right",
	INSIDE = "inside",
}

export type DisabledButtonInfo = {
	id: string
	text: string
}

export type LinkData = {
	to: string
	label: string
	isDisabled?: boolean
	disabledMessage?: string
}

export interface ICardComponentProps {
	children?: React.ReactNode
	title?: string
	translateTitle?: boolean
	filters?: FilterSort[]
	halfCard?: boolean
	button?: ComponentProps<typeof Button>
	customButton?: JSX.Element
	exportButton?: ComponentProps<typeof ExportButton>
	link?: LinkData
	hasXContentPadding?: boolean
	hasYContentPadding?: boolean
	/** Use this when doing full height cards and you need to scroll inside them */
	overflow?: "none" | "auto"
	/** Used when saving filters in URL query params */
	onTitleClick?: () => void
	variant?: CardComponentVariant
	containerRef?: MutableRefObject<HTMLDivElement | null>
	collapsed?: boolean
	onFilterOptionChange?: (activeOptions: ActiveOptions<any>) => void
	useSearchParams?: boolean
	filterLocation?: CardComponentFilterLocation
	alwaysExpanded?: boolean
}

export const Card: FC<ICardComponentProps> = ({
	children,
	title: _title = "",
	translateTitle = true,
	filters = [],
	halfCard,
	button,
	customButton,
	exportButton,
	link,
	hasXContentPadding = true,
	hasYContentPadding = true,
	overflow = "none",
	onTitleClick,
	variant = CardComponentVariant.STANDARD,
	containerRef,
	collapsed = false,
	onFilterOptionChange,
	useSearchParams,
	filterLocation = CardComponentFilterLocation.LEFT,
	alwaysExpanded = false,
}) => {
	const { t } = useTrans()

	const title = useMemo(() => (translateTitle ? t(_title) : _title), [translateTitle, t, _title])

	return (
		<FilterSortProvider
			filterSorts={filters}
			onOptionChange={onFilterOptionChange}
			useSearchParams={useSearchParams}
		>
			<div
				// print:h-0 prevents a bug when printing QR codes inside MultiListTable
				className={classNames([
					`flex w-full flex-col print:h-0 relative`,
					{ "row-auto/2": !halfCard },
				])}
				ref={containerRef}
			>
				<div
					className={classNames(
						"flex w-full max-w-full items-center justify-between gap-4 border-b-2 border-grey1",
						variant === CardComponentVariant.STANDARD && "bg-white py-3 px-4 sm:px-5",
						variant === CardComponentVariant.DRAWER && "bg-limeLight py-2 px-8"
					)}
				>
					<div
						className={classNames(
							"relative w-1 flex-1 flex flex-row items-center shrink",
							onTitleClick && "cursor-pointer",
							variant === CardComponentVariant.STANDARD && "my-1",
							variant === CardComponentVariant.DRAWER && "my-0"
						)}
						onClick={onTitleClick}
					>
						{title && (
							<h2 className="overflow-hidden sm:text-ellipsis sm:whitespace-nowrap text-xl sm:text-2xl pr-1">
								{title}
							</h2>
						)}

						{!!filters.length && filterLocation !== CardComponentFilterLocation.INSIDE && (
							<div
								className={classNames(
									"flex w-fit flex-1 shrink min-w-[150px] gap-4",
									title && "pl-2.5"
								)}
							>
								<FilterSorts
									{...{ filterSorts: filters, collapsed, alwaysExpanded, filterLocation }}
								/>
							</div>
						)}
					</div>
					{link && (
						<Link
							className={classNames(
								"pr-4 font-dmSans text-base font-medium underline",
								link.isDisabled && "opacity-50 cursor-not-allowed"
							)}
							to={link.isDisabled ? "#" : link.to}
							title={link.isDisabled ? link.disabledMessage : ""}
						>
							{link.label}
						</Link>
					)}
					{customButton || (button && <Button {...button} />)}
					{exportButton && <ExportButton {...exportButton} />}
				</div>
				<div
					className={classNames(
						"h-full bg-white flex-1",
						hasXContentPadding && "px-4 sm:px-8",
						hasYContentPadding && "py-6",
						overflow === "auto" && "overflow-auto"
					)}
				>
					{!!filters.length && filterLocation === CardComponentFilterLocation.INSIDE && (
						<div className={classNames("flex flex-1 gap-4 mx-4 mt-4 mb-3")}>
							<FilterSorts
								{...{ filterSorts: filters, collapsed, alwaysExpanded, filterLocation }}
							/>
						</div>
					)}
					{children}
				</div>
			</div>
		</FilterSortProvider>
	)
}
