import React, { useCallback, useState } from "react"
import { PieTooltipProps, ResponsivePie } from "@nivo/pie"
import { FilterType, PiechartInterface } from "components/Piechart/Piechart.interface"
import { formatNumberForTable } from "Utils/formatFunctions"
import { useConfig } from "api/hooks/useConfig"
import { useTrans } from "translations"
import { usePdfPreview } from "pages/home/pdfPreview"

const pieChartConfig = {
	margin: { top: 20, right: 20, bottom: 20, left: 20 },
	animate: true,
	activeOuterRadiusOffset: 8,
	innerRadius: 0.5,
	enableArcLinkLabels: false,
	enableArcLabels: false,
	padAngle: 1,
	colors: { datum: "data.color" },
}

export const Piechart: React.FC<PiechartInterface> = ({ data, filterLabel, suffix }) => {
	const { t } = useTrans()
	const { isMWM } = useConfig()
	const { pdfPreviewOpen } = usePdfPreview()
	const [filters, setFilters] = useState<FilterType>(null)
	const filteredData = data.filter(({ id }) => !filters?.includes(id))
	const filteredSumValues = filteredData.reduce((prev, curr) => prev + curr.value, 0)
	const filteredDataWithPercent = filteredData.map(d => ({
		...d,
		percent: filteredSumValues ? d.value / filteredSumValues : 0,
	}))

	const addFilter = (state: FilterType, id: string) => (!!state?.length ? [...state, id] : [id])

	const removeFilter = (state: FilterType, id: string) =>
		!!state?.length ? state?.filter(value => value !== id) : null

	const handleChangeFilters = ({
		target: { id, checked },
	}: React.ChangeEvent<HTMLInputElement>) => {
		if (!checked) {
			setFilters(prevState => addFilter(prevState, id))
		} else {
			setFilters(prevState => removeFilter(prevState, id))
		}
	}

	const getLegendLabel = useCallback(
		(id: string, label: string, value: number) => {
			let valueText = ""
			if (isMWM) {
				valueText = `${formatNumberForTable(value)} ${t("statisticsLabels:throws").toLowerCase()}`
			} else {
				const elementFromFiltered = filteredDataWithPercent.find(el => el.id === id)

				const weightText = formatNumberForTable(value, { suffix: " kg", numDecimals: 0 })
				valueText += weightText

				if (elementFromFiltered) {
					const percentText = formatNumberForTable(elementFromFiltered.percent * 100, {
						suffix: " %",
						numDecimals: 0,
					})
					valueText += `, ${percentText}`
				}
			}

			return (
				<>
					{label} - <b>{valueText}</b>
				</>
			)
		},
		[isMWM, t, filteredDataWithPercent]
	)

	return (
		<div className="flex h-full">
			<div className="grid min-h-[500px] sm:min-h-[300px] w-full grid-cols-1 sm:grid-cols-2 items-center gap-2">
				<ResponsivePie
					{...pieChartConfig}
					data={filteredDataWithPercent}
					valueFormat={value => formatNumberForTable(value, { suffix })}
					tooltip={props => <WasteKgPercentTooltip {...props} />}
				/>
				<div>
					{!pdfPreviewOpen && (
						<h6 className="mb-2.5 font-dmSans font-semibold text-black">{filterLabel}</h6>
					)}
					<ul>
						{data.map(({ id, label, color, value }) => (
							<li key={id} className="mb-2.5 flex items-center last:mb-0">
								<div className="relative h-4 w-4">
									{pdfPreviewOpen ? (
										<div
											className="h-4 w-4 rounded-full absolute"
											style={{ backgroundColor: color }}
										/>
									) : (
										<input
											id={id}
											type="checkbox"
											className="h-4 w-4 rounded-none text-red-600 accent-[#000] absolute"
											style={{ accentColor: color }}
											onChange={handleChangeFilters}
											defaultChecked
										/>
									)}
								</div>

								<label htmlFor={id} className="ml-2 text-xs">
									{getLegendLabel(id, label, value)}
								</label>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

const WasteKgPercentTooltip: React.FC<
	PieTooltipProps<{
		percent: number
		id: string
		label: string
		value: number
		color: string
	}>
> = ({
	datum: {
		label,
		color,
		value,
		data: { percent },
	},
}) => {
	const { t } = useTrans()
	const { isMWM } = useConfig()
	const formattedPercent = formatNumberForTable(percent * 100, { suffix: " %", numDecimals: 0 })
	const formattedValue = isMWM
		? `${formatNumberForTable(value)} ${t("statisticsLabels:throws").toLowerCase()}`
		: formatNumberForTable(value, { suffix: " kg", numDecimals: 0 })
	return (
		<div className="pointer-events-none relative top-0 left-0">
			<div className="rounded-br-sm bg-white py-2 px-3 shadow-md">
				<div className="flex items-center gap-2">
					<div>
						<span className="block h-3 w-3" style={{ backgroundColor: color }} />
					</div>
					<div>
						<span>{`${label}: `}</span>
						<span className="font-bold">{`${formattedValue}`}</span>
						{!isMWM && <span className="font-bold">{`, ${formattedPercent}`}</span>}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Piechart
