import { useLayoutEffect, useState } from "react"

type UseMediaQueryOptions = {
	defaultValue?: boolean
	initializeWithValue?: boolean
}

export enum MediaQuery {
	ExtraSmall = "(max-width: 640px)",
	Small = "(max-width: 768px)",
	Medium = "(max-width: 1024px)",
	Large = "(max-width: 1280px)",
	ExtraLarge = "(max-width: 1536px)",
}

export function useMediaQuery(
	query: MediaQuery | string,
	{ defaultValue = false, initializeWithValue = true }: UseMediaQueryOptions = {}
): boolean {
	const getMatches = (query: string): boolean => {
		return window.matchMedia(query).matches
	}

	const [matches, setMatches] = useState<boolean>(() => {
		if (initializeWithValue) {
			return getMatches(query)
		}
		return defaultValue
	})

	function handleChange() {
		setMatches(getMatches(query))
	}

	useLayoutEffect(() => {
		const matchMedia = window.matchMedia(query)

		handleChange()

		matchMedia.addEventListener("change", handleChange)
		return () => {
			matchMedia.removeEventListener("change", handleChange)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query])

	return matches
}
