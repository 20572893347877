import { useTrans } from "translations"

type RowType = {
	label: string
	name: string
	getView?: (value: any) => React.ReactNode
}

type Props = {
	rows: RowType[]
	data: { [k: string]: any }[]
	/**
	 * (Optional) List of rows by name to ignore when rendering the list.
	 */
	ignoreRowNames?: string[]
}

export const AveragesListMobile: React.FC<Props> = ({ data, rows, ignoreRowNames = [] }) => {
	const { t } = useTrans()

	return (
		<div className="flex flex-col divide-y">
			{rows.map(({ name, label, getView }) => {
				if (ignoreRowNames.includes(name)) {
					return null
				}
				const value = data?.[0]?.[name] ?? 0

				return (
					<div className="flex justify-between py-2">
						<div className="text-sm pl-2.5">{t(label)}</div>
						<div className="text-sm">{getView ? getView(value) : value}</div>
					</div>
				)
			})}
		</div>
	)
}
