import { LanguageType } from "admin-client-server/src/config-api"
import FormInput from "components/Form/FormInput"
import SelectInput from "components/Form/SelectInput"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useMemo } from "react"
import { useForm } from "react-hook-form"
import { useTrans } from "translations"
import { EMAIL_REGEX } from "Utils/isValidEmail"

export type EntryType = {
	id: string
	email: string
	language: LanguageType
}

type Props = {
	modalTitle: string
	onSave: (email: string, language: LanguageType, id: string) => void
	initialValues?: Partial<EntryType>
	existingRecipients: EntryType[]
}

export const AddOrUpdateRecipientModal: React.FC<Props> = ({
	modalTitle,
	onSave,
	initialValues,
	existingRecipients,
}) => {
	const { t } = useTrans()
	const { hideModal, ref: modalRef } = useModal()

	const formHandler = useForm({
		defaultValues: {
			email: initialValues?.email ?? "",
			language: initialValues?.language ?? "",
		},
		mode: "onChange",
	})
	const { control, register, formState, handleSubmit, setError, clearErrors } = formHandler

	const onSubmit = handleSubmit(eventFormData => {
		const { email, language } = eventFormData

		const isDuplicate = existingRecipients.some(recipient => recipient.email === email)

		if (isDuplicate) {
			setError(
				"email",
				{
					message: t("errors:emailRecipientUniquenessError"),
				},
				{ shouldFocus: true }
			)
			setError("root", {
				message: t("errors:emailRecipientUniquenessError"),
			})
		} else {
			clearErrors("root")
			onSave(email, language as LanguageType, initialValues?.id ?? "")
			hideModal()
		}
	})

	const languageOptions = useMemo(
		() =>
			Object.values(LanguageType)
				.map(code => ({
					label: t(`languages:${code}`),
					value: code,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[t]
	)

	return (
		<ModalContainer
			title={modalTitle}
			onConfirmText={"actions:save"}
			onConfirm={() => onSubmit()}
			onConfirmDisabled={!formState.isValid}
			onCancel={hideModal}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={formState.isDirty}
		>
			<div className="mb-6 flex flex-col gap-y-5">
				<FormInput
					name="email"
					label={t("formLabels:email")}
					register={register}
					onChange={() => formState.errors.root && clearErrors("root")}
					error={formState.errors.email}
					regexPattern={EMAIL_REGEX}
					patternError="errors:mustBeValidEmail"
					required
				/>
				<SelectInput
					label={t("formLabels:emailRecipientSelectLanguage")}
					name="language"
					control={control}
					options={languageOptions}
					menuPortalTarget={modalRef.current}
					required
				/>
			</div>
			{formState.errors.root && (
				<div className="text-carrotRed text-sm">{formState.errors.root.message}</div>
			)}
		</ModalContainer>
	)
}
