import { textView, ColumnType, RIGHT_ALIGN_CLASS } from "./functions"

export const inactiveTenantsColumns = (isSmallScreen = false): ColumnType[] => [
	{
		label: "entities:customer",
		name: "customerName",
		getView: textView,
	},
	{
		label: "statisticsLabels:previousRegistrations",
		name: "previousRegistrations",
		getView: value => textView(value, isSmallScreen ? RIGHT_ALIGN_CLASS : ""),
	},
]
