import { FC, useMemo } from "react"
import { formatNumberForTable, TFormatNumberSuffix } from "Utils/formatFunctions"
import "react-loading-skeleton/dist/skeleton.css"
import { LoadingIndicator } from "Icons/loadingIndicator"
import { Card, ICardComponentProps } from "./card"
import { EmptyView } from "components/EmptyView/EmptyView"

export const NumberCard: FC<
	{ numbers: INumberEntryProps[]; isLoading: boolean } & ICardComponentProps
> = ({
	numbers,
	title,
	filters,
	isLoading,
	useSearchParams,
	onFilterOptionChange,
	filterLocation,
}) => {
	const view = useMemo(() => {
		if (!numbers.length) {
			return <EmptyView type="failToFetch" />
		}

		return numbers.map((numberEntry, index) => (
			<NumberEntry
				key={`numberCard-entry-${index}`}
				{...numberEntry}
				data={isLoading ? "-" : numberEntry.data}
			/>
		))
	}, [isLoading, numbers])

	return (
		<Card
			title={title}
			filters={filters}
			onFilterOptionChange={onFilterOptionChange}
			useSearchParams={useSearchParams}
			filterLocation={filterLocation}
			halfCard
		>
			<div className="w-full flex-row justify-around">{view}</div>
		</Card>
	)
}

export interface INumberEntryProps {
	heading: string
	data: number | string
	suffix?: TFormatNumberSuffix
}

const NumberEntry: FC<INumberEntryProps> = ({ heading, data, suffix = "" }) => {
	return (
		<div className="sm:h-[119px] flex-col items-center justify-center">
			<>
				<div className="mx-2 mb-2 text-center font-medium text-sm">{heading}</div>
				{data === "-" ? (
					<div className="min-w-[200px] h-[52px] flex items-center justify-center">
						<LoadingIndicator />
					</div>
				) : (
					<div className="font-signifier text-3xl sm:text-2xl lg:text-4xl">
						{formatNumberForTable(data, { suffix, numDecimals: 0 })}
					</div>
				)}
			</>
		</div>
	)
}
