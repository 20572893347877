import { EmailJobType, LanguageType } from "admin-client-server/src/config-api"
import { MAX_EXPECTED_WASTE_CATEGORIES } from "admin-client-server/src/constants"
import { useConfig } from "api/hooks/useConfig"
import { useModal } from "Contexts"
import { FC, useCallback, useMemo } from "react"
import { useCommonEntitiesStore } from "States/commonEntities"
import { useGlobalAlert } from "States/globalAlert"
import { useTerminalsState } from "States/Terminals"
import { useTrans } from "translations"
import { trpc } from "Utils/trpc"
import { AddOrUpdateRecipientModal, EntryType } from "./components/addOrUpdateRecipientModal"
import { AddWasteCategoryModal } from "./components/addWasteCategoryModal"
import { ListForm } from "./components/listForm"
import { useConfigService } from "./useConfigService"
import { useEmailJob } from "./components/useEmailJob"
import { ConfirmModal } from "components/ConfirmModal"

export const ActionReport: FC = () => {
	const { t } = useTrans()
	const { showModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const { config } = useConfig()
	const { terminalConfig, isLoadingTerminalConfig } = useConfigService()
	const { currentTerminal } = useTerminalsState()
	const { refetchTerminalConfig } = useConfigService()
	const { wasteTypeCategories } = useCommonEntitiesStore()

	const {
		data: emailJobs = [],
		refetch: refetchEmailJobs,
		isLoading: isLoadingEmailJobs,
	} = trpc.config.getTerminalEmailJobsByType.useQuery({
		terminalId: currentTerminal.id,
		type: EmailJobType.ActionReport,
	})

	const {
		addEmailJob,
		updateEmailJob,
		deleteEmailJob,
		isAddingEmailJob,
		isDeletingEmailJob,
		isUpdatingEmailJob,
	} = useEmailJob({
		onSuccessFunc: refetchEmailJobs,
	})

	const { mutateAsync: updateTerminalConfig, isLoading: isUpdatingTerminalConfig } =
		trpc.config.updateTerminalConfig.useMutation({
			onSuccess: () => {
				refetchTerminalConfig()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:wasteCategorySaved",
				})
			},
			onError: error => {
				setGlobalAlert({
					type: "error",
					message: error.message,
				})
			},
		})

	const wasteCategories = useMemo(() => {
		return (
			(terminalConfig?.expectedWasteTypes
				.map(id => {
					const { name } = wasteTypeCategories?.find(w => w.id === id) || {}
					return { id, name }
				})
				.filter(Boolean) as { id: string; name: string }[]) ?? []
		)
	}, [terminalConfig, wasteTypeCategories])

	const addEmailRecipient = useCallback(
		(email: string, language: LanguageType) => {
			addEmailJob({
				terminalId: currentTerminal.id,
				email,
				language,
				type: EmailJobType.ActionReport,
			})
		},
		[addEmailJob, currentTerminal.id]
	)

	const editEmailRecipient = useCallback(
		(email: string, language: LanguageType, id: string) => {
			updateEmailJob({
				emailJobId: id.toString(),
				email,
				language,
			})
		},
		[updateEmailJob]
	)

	const addWasteCategory = useCallback(
		(newWasteCategory: string) => {
			updateTerminalConfig({
				terminalId: currentTerminal.id,
				expectedWasteTypes: [...wasteCategories.map(c => c.id), newWasteCategory],
			})
		},
		[wasteCategories, updateTerminalConfig, currentTerminal.id]
	)

	const removeWasteCategory = useCallback(
		(id: string) => {
			updateTerminalConfig({
				terminalId: currentTerminal.id,
				expectedWasteTypes: wasteCategories.filter(c => c.id !== id).map(c => c.id),
			})
		},
		[wasteCategories, updateTerminalConfig, currentTerminal.id]
	)

	const openAddActionReportRecipientModal = useCallback(() => {
		showModal(
			<AddOrUpdateRecipientModal
				modalTitle={t("formLabels:addEmailRecipient")}
				onSave={addEmailRecipient}
				initialValues={{
					language: terminalConfig?.language ?? config.language ?? LanguageType.En,
				}}
				existingRecipients={emailJobs}
			/>
		)
	}, [addEmailRecipient, config.language, showModal, terminalConfig?.language, emailJobs, t])

	const openEditActionReportRecipientModal = useCallback(
		(entry: EntryType) => {
			showModal(
				<AddOrUpdateRecipientModal
					modalTitle={t("formLabels:editEmailRecipient")}
					onSave={editEmailRecipient}
					initialValues={{
						id: entry.id,
						email: entry.email,
						language: entry.language,
					}}
					existingRecipients={emailJobs}
				/>
			)
		},
		[editEmailRecipient, showModal, emailJobs, t]
	)

	const openConfirmDeleteRecipientModal = useCallback(
		(id: string) => {
			showModal(
				<ConfirmModal
					title="formLabels:deleteEmailRecipient"
					hint={t("hints:deleteEmailRecipient")}
					onConfirm={() => deleteEmailJob({ emailJobId: id.toString() })}
				/>
			)
		},
		[showModal, t, deleteEmailJob]
	)

	const openAddWasteCategoryModal = useCallback(() => {
		const options = wasteTypeCategories
			?.map(c => ({
				value: c.id,
				label: c.name,
			}))
			.filter(c => !wasteCategories.some(ec => ec.id === c.value))

		showModal(<AddWasteCategoryModal onSave={addWasteCategory} options={options} />)
	}, [wasteTypeCategories, showModal, addWasteCategory, wasteCategories])

	const openConfirmWasteCategoryModal = useCallback(
		(id: string) => {
			showModal(
				<ConfirmModal
					title="formLabels:deleteWasteCategory"
					hint={t("hints:deleteWasteCategory")}
					onConfirm={() => removeWasteCategory(id)}
				/>
			)
		},
		[showModal, t, removeWasteCategory]
	)

	return (
		<div className="space-y-4">
			<p>{t("hints:actionReportRecipient")}</p>
			<ListForm
				data={emailJobs}
				title={t("formLabels:recipients")}
				buttonTitle={t("formLabels:addRecipient")}
				onAdd={openAddActionReportRecipientModal}
				onEdit={openEditActionReportRecipientModal}
				onDelete={openConfirmDeleteRecipientModal}
				entryRender={entry => ({
					label: entry.email,
					labelBottom: t(`languages:${entry.language}`),
				})}
				className="max-w-4xl !mb-16"
				disabled={isAddingEmailJob || isUpdatingEmailJob || isDeletingEmailJob}
				loadingData={isLoadingEmailJobs}
				emptyMessage={t("errors:noRecipients")}
				addButtonDisabled={wasteCategories.length >= MAX_EXPECTED_WASTE_CATEGORIES}
			/>
			<p>{t("hints:expectedWasteCategories")}</p>
			<ListForm
				data={wasteCategories}
				title={t("statisticsLabels:wasteCategories")}
				buttonTitle={t("formLabels:addWasteCategory")}
				onAdd={openAddWasteCategoryModal}
				onDelete={openConfirmWasteCategoryModal}
				entryRender={entry => ({ label: entry.name })}
				className="max-w-4xl"
				disabled={isUpdatingTerminalConfig}
				loadingData={isLoadingTerminalConfig}
				emptyMessage={t("errors:noWasteCategories")}
				addButtonDisabled={wasteCategories.length >= MAX_EXPECTED_WASTE_CATEGORIES}
			/>
		</div>
	)
}
