import { useTerminals } from "api/hooks/useTerminals"
import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { FC, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { TerminalForm, TerminalFormData } from "./TerminalForm"
import { useConfig } from "api/hooks/useConfig"
import { BuildingForm } from "./BuildingForm"
import { updatePoint } from "api/mutations/updatePoint"
import { useGlobalAlert } from "States/globalAlert"
import {
	ACCESS_POINT_AREA_KEY,
	ACCESS_POINT_EMAIL_KEY,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_TYPE_KEY,
	REAL_ESTATE_CATEGORY_KEY,
} from "constants/general"
import { RealEstateCategory } from "api/types"
import { RealEstate } from "admin-client-server/src/coreApi/models/RealEstate"
import { useEmailJob } from "pages/configuration/components/useEmailJob"
import { EmailJobType } from "admin-client-server/src/config-api"

export type Terminal = RealEstate & {
	id: string
}

interface EditTerminalModalProps {
	terminal: Terminal
}

const terminalToFormData = (terminal: Terminal): TerminalFormData => ({
	...terminal,
	name: terminal?.name ?? "",
	category: terminal?.category ?? RealEstateCategory.Office,
	contactEmail: terminal?.operations.email ?? "",
	address: terminal?.address ?? "",
	area: terminal?.area ?? "",
})

export const EditTerminalModal: FC<EditTerminalModalProps> = ({ terminal }) => {
	const { refetchTerminals, isLoadingTerminals } = useTerminals()

	const { hideModal } = useModal()
	const { isMWM } = useConfig()
	const { setGlobalAlert } = useGlobalAlert()
	const { addEmailJob } = useEmailJob()
	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

	const formData = terminal && terminalToFormData(terminal)

	const formHandler = useForm<TerminalFormData>({
		defaultValues: formData,
	})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const handleUpdate = (terminalFormData: TerminalFormData) => {
		if (!terminal || !formData) return

		const propertiesUpdate = isMWM
			? []
			: ([
					{ key: REAL_ESTATE_CATEGORY_KEY, value: terminalFormData?.category },
					{ key: ACCESS_POINT_EMAIL_KEY, value: terminalFormData?.contactEmail },
					{ key: ACCESS_POINT_AREA_KEY, value: terminalFormData?.area },
				].filter(Boolean) as { key: string; value: string }[])

		updatePoint(
			{
				accessPoint: {
					id: terminal.id,
				},
				updateFields: {
					[ACCESS_POINT_NAME_KEY]: terminalFormData?.name,
					[ACCESS_POINT_TYPE_KEY]: "TERMINAL",
				},
				propertiesUpdate: {
					mode: "MERGE",
					props: propertiesUpdate,
				},
			},
			async () => {
				const isUpdatedEmail = terminalFormData.contactEmail !== terminal.operations.email
				if (isUpdatedEmail) {
					await addEmailJob({
						terminalId: terminal.id,
						email: terminalFormData.contactEmail,
						type: EmailJobType.ActionReport,
					})
				}

				await refetchTerminals()
				setGlobalAlert({
					type: "success",
					message: "systemMessages:changesSaved",
				})
				hideModal()
			}
		)
	}

	const onSubmit = handleSubmit(terminalFormData => {
		setIsSubmitAttempted(true)
		handleUpdate(terminalFormData)
	})

	const isConfirmDisabled = useMemo(
		() => isSubmitAttempted && (!isEmpty(errors) || !isValid),
		[isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="drawerLabels:terminalDetails"
			onConfirm={() => onSubmit()}
			onCancel={hideModal}
			onConfirmLoading={isLoadingTerminals}
			onConfirmDisabled={isConfirmDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			{isMWM ? (
				<TerminalForm formHandler={formHandler} />
			) : (
				<BuildingForm formHandler={formHandler} />
			)}
		</ModalContainer>
	)
}
