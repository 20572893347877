import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { isEmpty } from "lodash"
import { useForm } from "react-hook-form"
import { useTerminals } from "api/hooks/useTerminals"
import { BuildingForm, BuildingFormData } from "./BuildingForm"
import { addAccessPoint } from "api/addAccessPoint"
import { useNavigate } from "react-router-dom"
import { terminalIdGenerator } from "Utils/uniqueIdGenerator"
import {
	ACCESS_POINT_EMAIL_KEY,
	ACCESS_POINT_NAME_KEY,
	ACCESS_POINT_TYPE_KEY,
	EXTERNAL_ID_KEY,
	EXTERNAL_KEYS_KEY,
	PROPERTIES_KEY,
	REAL_ESTATE_CATEGORY_KEY,
	ACCESS_POINT_AREA_KEY,
} from "constants/general"
import { useGlobalAlert } from "States/globalAlert"
import { useMemo, useState } from "react"
import { useEmailJob } from "pages/configuration/components/useEmailJob"
import { EmailJobType } from "admin-client-server/src/config-api"

export const CreateBuildingModal = () => {
	const { hideModal } = useModal()
	const navigate = useNavigate()
	const { refetchTerminals, isLoadingTerminals } = useTerminals()
	const { addEmailJob } = useEmailJob()
	const { setGlobalAlert } = useGlobalAlert()
	const [isSubmitAttempted, setIsSubmitAttempted] = useState(false)

	const formHandler = useForm<BuildingFormData>({})

	const {
		formState: { errors, isValid, isDirty },
		handleSubmit,
	} = formHandler

	const onSuccess = async (terminalId: string, contactEmail: string) => {
		await addEmailJob({
			terminalId,
			email: contactEmail,
			type: EmailJobType.ActionReport,
		})
		await refetchTerminals()

		setGlobalAlert({
			type: "success",
			message: "systemMessages:terminalAdded",
		})
		hideModal()
		navigate(`/infrastructure/manage/${terminalId}`)
	}

	const setupBuilding = (formData: BuildingFormData) => {
		const externalIdValue = terminalIdGenerator(formData.name)
		addAccessPoint({
			accessPoint: {
				[ACCESS_POINT_NAME_KEY]: formData.name,
				[EXTERNAL_KEYS_KEY]: [{ key: EXTERNAL_ID_KEY, value: externalIdValue }],
				[ACCESS_POINT_TYPE_KEY]: "TERMINAL",
				[PROPERTIES_KEY]: [
					{ key: ACCESS_POINT_EMAIL_KEY, value: formData.contactEmail },
					{ key: REAL_ESTATE_CATEGORY_KEY, value: formData.category },
					{ key: "downstreamHandlingJSON", value: JSON.stringify([]) },
					{ key: ACCESS_POINT_AREA_KEY, value: formData.area },
					{ key: "accessToken", value: externalIdValue },
				],
			},
			onStartCallBack: res => {
				onSuccess(res.modifiedPoint.id, formData.contactEmail)
			},
		})
	}

	const onSubmit = handleSubmit(formData => {
		setIsSubmitAttempted(true)
		setupBuilding(formData)
	})

	const isSubmitDisabled = useMemo(
		() => isSubmitAttempted && (!isEmpty(errors) || !isValid),
		[isSubmitAttempted, errors, isValid]
	)

	return (
		<ModalContainer
			title="actions:createTerminal"
			onConfirm={() => onSubmit()}
			onConfirmLoading={isLoadingTerminals}
			onCancel={hideModal}
			onConfirmDisabled={isSubmitDisabled}
			className="w-3/4 sm:w-1/2 lg:w-1/3"
			showDiscardModal={isDirty}
		>
			<BuildingForm formHandler={formHandler} />
		</ModalContainer>
	)
}
