import { FC, useEffect, useState, useMemo, useCallback } from "react"
import { Button } from "components/button/index"
import { SingleValue } from "react-select"
import { useTrans } from "translations"
import { OptionalClientType, useConfig } from "api/hooks/useConfig"
import ClientType from "admin-client-server/src/utils/clientType"
import { Loading } from "App"
import { useCommonEntitiesStore } from "States/commonEntities"
import {
	clientTypeOverride,
	siteName,
	useExternalCRMOverride,
	useWasteSuctionSystemOverride,
	wtcsOverride,
} from "Configs/config"
import { capitalize } from "lodash"
import timezones from "timezones-list"
import { Toggle } from "components/Toggle"
import { StyledSelect } from "components/StyledSelect"
import { LanguageType } from "admin-client-server/src/config-api"
import { trpc } from "Utils/trpc"
import { useModal } from "Contexts"
import { ConfirmModal } from "components/ConfirmModal"
import { useGlobalAlert } from "States/globalAlert"

type Props = {
	setHasUnsavedChanges: (hasChanges: boolean) => void
}

export const Client: FC<Props> = ({ setHasUnsavedChanges }) => {
	const { config, updateConfig, isUpdatingConfig, isMWM } = useConfig()
	const { wasteTypeClassificationSystems } = useCommonEntitiesStore()

	const [clientType, setClientType] = useState<OptionalClientType>(config?.type)
	const [useExternalCRM, setUseExternalCRM] = useState(config?.useExternalCRM ?? false)
	const [useWasteSuctionSystem, setUseWasteSuctionSystem] = useState(
		config?.useWasteSuctionSystem ?? false
	)
	const [wasteTypeClassificationSystem, setWasteTypeClassificationSystem] = useState<string>(
		config?.wasteTypeClassificationSystemId || ""
	)
	const [timezone, setTimezone] = useState<string>(config?.timezone || "")
	const [language, setLanguage] = useState<LanguageType | undefined>(config?.language)

	const { showModal } = useModal()
	const { setGlobalAlert } = useGlobalAlert()
	const {
		mutateAsync: addAllContactPersonsToActionReport,
		isLoading: isAddingAllContactPersonsToEmailJob,
	} = trpc.config.addAllContactPersonsToActionReportForClient.useMutation()

	const { t } = useTrans()

	useEffect(() => {
		setUseExternalCRM(config?.useExternalCRM ?? false)
		setClientType(config?.type)
	}, [config])

	useEffect(() => {
		const hasChangedClientType = config?.type !== clientType
		const hasChangedUseExternalCRM = config?.useExternalCRM !== useExternalCRM
		const hasChangedWasteTypeClassificationSystem =
			!!wasteTypeClassificationSystem &&
			config?.wasteTypeClassificationSystemId !== wasteTypeClassificationSystem

		const hasChanges =
			hasChangedClientType || hasChangedUseExternalCRM || hasChangedWasteTypeClassificationSystem
		setHasUnsavedChanges(hasChanges)
	}, [useExternalCRM, clientType, setHasUnsavedChanges, config, wasteTypeClassificationSystem])

	const typeOptions = useMemo(() => {
		return Object.values(ClientType).map(type => ({
			label: t(`common:${type}`),
			value: type,
		}))
	}, [t])

	const wasteTypeClassificationSystemOptions = useMemo(() => {
		return (
			wasteTypeClassificationSystems?.map(w => ({
				label: w.name,
				value: w.id,
			})) || []
		)
	}, [wasteTypeClassificationSystems])

	const timezoneOptions = useMemo(
		() =>
			timezones
				.map(t => ({
					label: t.label,
					value: t.tzCode,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[]
	)

	const languageOptions = useMemo(
		() =>
			Object.values(LanguageType)
				.map(code => ({
					label: t(`languages:${code}`),
					value: code,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[t]
	)

	const onSave = useCallback(() => {
		if (isUpdatingConfig) return // Prevent multiple submissions if already updating

		updateConfig({
			useExternalCRM,
			type: clientType!,
			wasteTypeClassificationSystemId: wasteTypeClassificationSystem,
			useWasteSuctionSystem,
			timezone,
			language,
		})
	}, [
		isUpdatingConfig,
		updateConfig,
		useExternalCRM,
		clientType,
		wasteTypeClassificationSystem,
		useWasteSuctionSystem,
		timezone,
		language,
	])

	const onAddAllContactPersonsToEmailJob = useCallback(() => {
		showModal(
			<ConfirmModal
				onConfirm={async () => {
					await addAllContactPersonsToActionReport()
					setGlobalAlert({
						type: "success",
						message: "systemMessages:changesSaved",
					})
				}}
				hint={t("hints:addAllContactPersonsToEmailJob")}
				title="configLabels:addAllContactPersonsToEmailJob"
			/>
		)
	}, [addAllContactPersonsToActionReport, setGlobalAlert, showModal, t])

	const onClientTypeChange = useCallback(
		(change: SingleValue<(typeof typeOptions)[number]>) => {
			setClientType(change?.value as ClientType)
		},
		[setClientType]
	)

	const onWasteTypeClassificationSystemChange = useCallback(
		(change: SingleValue<(typeof wasteTypeClassificationSystemOptions)[number]>) => {
			setWasteTypeClassificationSystem(change?.value as string)
		},
		[setWasteTypeClassificationSystem]
	)

	const onTimezoneChange = useCallback(
		(change: SingleValue<(typeof timezoneOptions)[number]>) => {
			setTimezone(change?.value as string)
		},
		[setTimezone]
	)

	const onLanguageChange = useCallback(
		(change: SingleValue<(typeof languageOptions)[number]>) => {
			setLanguage(change?.value as LanguageType)
		},
		[setLanguage]
	)

	const clientName = capitalize(siteName)

	if (!config) {
		return <Loading />
	}

	return (
		<div className="space-y-4">
			<h2 className="text-2xl">{clientName}</h2>
			<a
				href="https://www.notion.so/carrottech/Configurations-that-can-be-set-for-Carrot-Insight-obs-also-RE-d7b5e85f808a4c04b6ec7702d083a6ba"
				className="underline"
			>
				{t("configLabels:documentation")}
			</a>
			<div className="max-w-[380px]">
				<label className="select-none">{t("configLabels:customerSegment")} *</label>
				<StyledSelect
					className="mt-1"
					required
					options={typeOptions}
					isDisabled={[ClientType.Mwm.toString(), ClientType.RealEstate.toString()].includes(
						clientTypeOverride ?? ""
					)}
					value={typeOptions.find(o => o.value === clientType)}
					onChange={onClientTypeChange}
				/>
			</div>
			<div className="max-w-[380px]">
				<label className="select-none">{t("formLabels:defaultWTCS")} *</label>
				<StyledSelect
					className="mt-1"
					options={wasteTypeClassificationSystemOptions}
					value={wasteTypeClassificationSystemOptions.find(
						o => o.value === wasteTypeClassificationSystem
					)}
					isDisabled={wtcsOverride !== undefined}
					onChange={onWasteTypeClassificationSystemChange}
				/>
			</div>
			<div className="max-w-[380px]">
				<label className="select-none">{t("formLabels:defaultTimezone")} *</label>
				<StyledSelect
					className="mt-1"
					options={timezoneOptions}
					value={timezoneOptions.find(o => o.value === timezone)}
					onChange={onTimezoneChange}
				/>
			</div>
			<div className="max-w-[380px]">
				<label className="select-none">{t("configLabels:clientLanguage")} *</label>
				<StyledSelect
					className="mt-1"
					options={languageOptions}
					value={languageOptions?.find(o => o.value === language)}
					onChange={onLanguageChange}
				/>
			</div>
			{isMWM && (
				<div className="flex-col gap-y-4">
					<Toggle
						label={t("configLabels:useExternalCRM")}
						checked={useExternalCRM}
						disabled={useExternalCRMOverride !== undefined}
						onChange={() => setUseExternalCRM(!useExternalCRM)}
					/>
					<Toggle
						label={t("configLabels:useWasteSuctionSystem")}
						checked={useWasteSuctionSystem}
						disabled={useWasteSuctionSystemOverride !== undefined}
						onChange={() => setUseWasteSuctionSystem(!useWasteSuctionSystem)}
					/>
				</div>
			)}
			{!isMWM && (
				<Button
					label="configLabels:addAllContactPersonsToEmailJob"
					loading={isAddingAllContactPersonsToEmailJob}
					color="black"
					onClick={onAddAllContactPersonsToEmailJob}
				/>
			)}
			<hr className="-mx-4" />
			<Button
				label="actions:save"
				loading={isUpdatingConfig}
				onClick={onSave}
				disabled={isUpdatingConfig}
			/>
		</div>
	)
}
