import React from "react"
import UserButton from "UI/Header/components/UserButton"
import HamburgerIcon from "Icons/Hamburger"
import { CloseIcon } from "Icons/Close"

interface SearchbarProps {
	toggleMenu: () => void
	sidebarOpen: boolean
}

export const Searchbar: React.FC<SearchbarProps> = ({ toggleMenu, sidebarOpen }) => {
	return (
		<div className="fixed top-0 right-0 z-20 flex h-16 w-full items-center justify-between bg-white px-4 sm:px-8 md:w-[calc(100%-theme(space.64))] ">
			<button onClick={toggleMenu} className="md:opacity-0">
				{sidebarOpen ? <CloseIcon /> : <HamburgerIcon />}
			</button>
			<UserButton />
		</div>
	)
}

export default Searchbar
