import ModalContainer from "components/modalContainer"
import { useModal } from "Contexts"
import { useTrans } from "translations"

export const ConfirmModal: React.FC<{
	title?: string
	hint?: string
	entityName?: string
	onConfirm: () => void
}> = ({ title = "actions:delete", hint, entityName, onConfirm }) => {
	const { t } = useTrans()
	const { hideModal } = useModal()

	const handleConfirm = () => {
		hideModal()
		onConfirm()
	}

	return (
		<ModalContainer
			title={title}
			onConfirmText={"actions:confirm"}
			onConfirm={handleConfirm}
			onCancel={hideModal}
		>
			<div className="pt-3 max-w-lg">
				<p>{hint ?? t("hints:areYouSureDelete", { name: entityName })}</p>
			</div>
		</ModalContainer>
	)
}
