import { noop, orderBy } from "lodash"
import { twMerge } from "tailwind-merge"
import React from "react"
import TableHeader from "../TableHeader"
import useSorting from "Utils/useSorting"
import { TableRow } from "./TableRow"
import { ColumnType } from "Utils/tableColumns/functions"
import { EmptyView } from "components/EmptyView/EmptyView"
import { EmptyDataType } from "components/EmptyView/emptyViewTypes"
import { useTrans } from "translations"

type Props = {
	columns: ColumnType[]
	data: { [k: string]: any }[]
	isCollapsible?: boolean
	getExpandedRowView?: (values: { [k: string]: any }) => React.ReactNode
	className?: string
	headerClassName?: string
	rowClassName?: string
	sortable?: boolean
	sortingHeader?: string
	rightAlign?: boolean
	emptyViewType?: EmptyDataType
	emptyViewClassName?: string
	showHeadersWhenEmpty?: boolean
	hasDelete?: boolean
	hideDeleteSingle?: boolean
	limitedHeight?: boolean
	deleteFunction?: (id: string) => void
	showPreview?: boolean
	previewHintKey?: string
	isSmallScreen?: boolean
}

export const Table: React.FC<Props> = ({
	data,
	columns,
	isCollapsible = false,
	getExpandedRowView,
	className = "",
	headerClassName = "",
	rowClassName = "",
	sortable = true,
	sortingHeader = "",
	rightAlign = false,
	emptyViewType,
	emptyViewClassName = "",
	showHeadersWhenEmpty = true,
	hasDelete = false,
	limitedHeight,
	deleteFunction = noop,
	showPreview = false,
	previewHintKey = "",
	isSmallScreen = false,
}) => {
	const { t } = useTrans()
	const { field, direction, changeSorting } = useSorting(sortingHeader)

	const sortedData = orderBy(data, field, direction)

	return (
		<div className={twMerge("mb-2.5 text-end", className)}>
			<div className={twMerge(isSmallScreen && "overflow-x-auto max-w-full")}>
				{(sortedData?.length > 0 || showHeadersWhenEmpty) && (
					<div
						className={twMerge(
							`bg-white min-h-[48px] top-0 z-10 grid grid-cols-${columns.length} py-1 border-b border-grey3`,
							hasDelete && "pr-10",
							isSmallScreen && "min-w-[900px]",
							headerClassName
						)}
					>
						{columns.map((h, i) => {
							const isColumnSortable = h.sortable != null ? h.sortable : sortable
							return (
								<TableHeader
									key={`${h.name}-${i}`}
									label={h.label}
									field={h.name}
									setSort={isColumnSortable ? changeSorting : undefined}
									activeField={field}
									direction={direction}
									tooltipText={h.headerTooltipText}
									tooltipComponent={h.headerTooltipComponent}
									className={twMerge(
										rightAlign && "justify-end text-end pr-3",
										i === 0 && `justify-start ${isCollapsible && "pl-10"} text-start`,
										h.headerClassNames
									)}
								/>
							)
						})}
					</div>
				)}

				<div
					className={twMerge(
						isSmallScreen && "min-w-[900px]",
						limitedHeight && "max-h-[240px] overflow-y-auto"
					)}
				>
					{sortedData?.length > 0
						? sortedData.map((values, i) => (
								<TableRow
									key={`${values.name}-${i}`}
									values={values}
									columns={columns}
									isCollapsible={isCollapsible}
									getExpandedRowView={getExpandedRowView}
									rowClassName={rowClassName}
									hasDelete={hasDelete}
									deleteFunction={deleteFunction}
									isLast={i === sortedData.length - 1}
								/>
							))
						: emptyViewType && (
								<EmptyView
									type={emptyViewType}
									className={twMerge("mt-5 min-h-[75px]", emptyViewClassName)}
								/>
							)}
				</div>

				{sortedData?.length > 0 && showPreview && (
					<div className="text-sm text-grey6 flex whitespace-pre-wrap justify-center align-center text-center py-4 border-y border-t border-grey3">
						{t(previewHintKey)}
					</div>
				)}
			</div>
		</div>
	)
}
